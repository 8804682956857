<template>
   <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
    :headers="headers"
      :items="visitsPositions"
      :search="search"
    >
    <template v-slot:item.positionCode="{ item }">
        <v-text-field
          v-model="item.positionCode"
        ></v-text-field>
      </template>
  
  
  </v-data-table>
  <v-card-actions>
    <v-btn @click="submitForm" >Modifica</v-btn>
  </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: "VisitPositions",
  data() {
    return {
      visitsPositions: [],
      search: null,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'UnitId', value: 'unitId' },
        { text: 'Position code', value: 'positionCode' },
      ],
    };
  },
  async mounted() {
    this.visitsPositions = (await this.$api.yardData.getVisitsPositions());
    this.visitsPositions.forEach(item=> item.oldPositionCode=item.positionCode);
    console.log(this.visitsPositions);
  },
  async created() {},

  methods: {
    async submitForm(){
      const changed=this.visitsPositions.filter(item=> item.positionCode != item.oldPositionCode);
      if (changed.length==0) return;
      this.visitsPositions.forEach(item=> item.oldPositionCode=item.positionCode);
      await this.$api.yardData.setVisitsPositions(changed);
    }

  },
  components: {  },
};
</script>
